// vue
import { ref } from 'vue'

// types
import type { OrbitApiRequestPayload } from '@revolutionprep/types'

export function useForms () {
  /**
   * state
   * ==================================================================
   */
  const payload = ref<OrbitApiRequestPayload>({})
  const isProcessing = ref(false)

  /**
   * methods
   * ==================================================================
   */
  function updateProperty (prop: OrbitApiRequestPayload) {
    payload.value = { ...payload.value, ...prop }
  }

  function toggleIsProcessing (value: boolean) {
    isProcessing.value = value
  }

  return {
    isProcessing,
    payload,
    updateProperty,
    toggleIsProcessing
  }
}
